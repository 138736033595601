import {
  type StorefrontWidget,
  type StorefrontWidgetTypeAll,
  TranslationRestrictedWidgets,
  WidgetDynamicTypeEnum
} from 'ecosystem'

export const showsOnMobile = (widget: StorefrontWidget) => {
  if ('showsOnMobile' in widget.widget) {
    return widget.widget.showsOnMobile ? 'initial' : 'none'
  }

  return 'initial'
}

export const parseObjectToClassnames = (object?: Record<string, any>) => {
  if (!object) {
    return void 0
  }

  const replacementArray = Object.keys(object)
    // this is the transformation string
    .map((k) => ({ [k]: `.${k}` }))
  const replacements = Object.assign({}, ...replacementArray)

  const replacedItems = Object.keys(object).map((key) => {
    const newKey = replacements[key as any] || key
    return { [newKey]: object[key] }
  })

  if (!replacedItems.length) {
    return {}
  }
  return replacedItems.reduce((a, b) => ({ ...a, ...b }))
}

export function isWidgetDynamicTypeEnum(value: any): value is WidgetDynamicTypeEnum {
  return Object.values(WidgetDynamicTypeEnum).includes(value)
}

export function isTranslationRestrictedWidgetType(
  type: StorefrontWidgetTypeAll
): type is (typeof TranslationRestrictedWidgets)[keyof typeof TranslationRestrictedWidgets] {
  return Object.values(TranslationRestrictedWidgets).includes(type as any)
}
