import React, { cloneElement, memo, type ReactNode } from 'react'
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  type DrawerProps
} from '@chakra-ui/react'

interface LocalSlideoutProps extends Partial<DrawerProps> {
  trigger: ReactNode
  title?: string
  children: ReactNode
  hideCloseButton?: boolean
  onOpen: () => void
  onClose: () => void
  isOpen: boolean
  placement?: 'left' | 'right' | 'top' | 'bottom'
}

const LocalSlideoutComponent: React.FC<LocalSlideoutProps> = ({
  hideCloseButton,
  trigger,
  title,
  children,
  onClose,
  onOpen,
  isOpen,
  placement = 'right',
  ...props
}) => {
  const Trigger = cloneElement(trigger as React.ReactElement, {
    onClick: (e: any) => {
      e.stopPropagation()
      onOpen()
    }
  })

  return (
    <>
      {Trigger}

      <Drawer placement={placement} onClose={onClose} isOpen={isOpen} {...props}>
        <DrawerOverlay />
        <DrawerContent>
          {!!title && (
            <DrawerHeader fontSize="sm" textAlign="center">
              {title}
            </DrawerHeader>
          )}
          {!hideCloseButton && (
            <DrawerCloseButton aria-label="close" _focus={{ outline: 'none' }} zIndex={1} />
          )}
          <DrawerBody
            mx="auto"
            maxW="container.xl"
            w="full"
            display="flex"
            alignItems="stretch"
            justifyContent="flex-start"
            h="100%">
            {children}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

const LocalSlideout = memo(LocalSlideoutComponent)

export default LocalSlideout
