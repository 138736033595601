'use client'
import type { FC } from 'react'
import { Suspense } from 'react'
import type { StorefrontWidget } from 'ecosystem'
import { ProductList } from '../components/product-list'
import useProductList from '../hooks/useProductList'
import { type WidgetImplementation } from '../../widgets/registry/typings'
import type { IProductList } from '../components/product-list/ProductList'

export interface ProductListWidgetType {
  name: string
}

type ProductListWidgetProps = WidgetImplementation<StorefrontWidget<ProductListWidgetType>> &
  Pick<
    IProductList,
    'onProductRequest' | 'productsState' | 'onAddToCart' | 'onMore' | 'filterProps' | 'showsPrice'
  > & {
    paths: {
      getInitialProducts: string
      productsUrlPath: string
    }
    customComponents?: IProductList['customComponents'] & {
      ProductList?: FC<IProductList>
    }
  }

const ProductListWidget: FC<ProductListWidgetProps> = ({
  productsState,
  paths,
  dynamicProps,
  customComponents: { ProductList: CustomProductList, ...customComponents } = {},
  filterProps,
  widgetId,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars -- need to exclude from ...props
  widget,
  showsPrice,
  ...props
}) => {
  const { products, totalElements, loadMore, isLoading, isNextPageAvailable } = useProductList({
    id: dynamicProps?.viewId || '',
    type:
      (dynamicProps?.type.toLowerCase() as 'category' | 'brand' | 'tag' | undefined) || 'category',
    filterConfig: dynamicProps?.filterConfig || {
      brands: [],
      options: []
    },
    productsState,
    getInitialProducts: paths.getInitialProducts,
    sortingOptions: filterProps.sortingOptions
  })

  const ProductListComponent = CustomProductList || ProductList

  return (
    <ProductListComponent
      deliveryOptions={dynamicProps?.deliveryOptions ?? null}
      infiniteScrollConfig={{
        loadMore,
        shouldLoadMore: isNextPageAvailable
      }}
      items={products}
      productsUrlPath={paths.productsUrlPath}
      py={0}
      sectionId={widgetId}
      title=""
      totalItems={totalElements}
      {...{
        isLoading,
        productsState,
        customComponents,
        filterProps,
        showsPrice,
        ...props
      }}
    />
  )
}

// https://nextjs.org/docs/messages/missing-suspense-with-csr-bailout
const ProductListWidgetSuspended: FC<ProductListWidgetProps> = (props) => {
  const {
    productsState,
    paths,
    dynamicProps,
    customComponents: { ProductList: CustomProductList, ...customComponents } = {},
    filterProps,
    widgetId,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars -- need to exclude from ...props
    widget,
    showsPrice,
    ...restProps
  } = props
  const ProductListComponent = CustomProductList || ProductList

  return (
    <Suspense
      fallback={
        <ProductListComponent
          showsPrice={showsPrice}
          deliveryOptions={dynamicProps?.deliveryOptions ?? null}
          infiniteScrollConfig={{
            loadMore: () => void 0,
            shouldLoadMore: false
          }}
          items={[]}
          productsUrlPath={paths.productsUrlPath}
          py={0}
          sectionId={widgetId}
          title=""
          totalItems={0}
          {...{
            isLoading: true,
            productsState,
            customComponents,
            filterProps,
            ...restProps
          }}
        />
      }>
      <ProductListWidget {...props} />
    </Suspense>
  )
}

export default ProductListWidgetSuspended
