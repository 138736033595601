import type { FC } from 'react'
import type { CategoryDTO, StorefrontWidget } from 'ecosystem'
import { overrideText } from 'ui/lib/overrides'
import AllCAtegoriesList from '../components/AllCategoriesList'
import { type WidgetImplementation } from '../../widgets/registry/typings'

export interface CategoryTreeWidgetType {
  name?: string
}

type CategoryTreeWidgetProps = WidgetImplementation<StorefrontWidget<CategoryTreeWidgetType>> & {
  overrides?: CategoryTreeWidgetOverrides
  categoriesPath: string
  category: CategoryDTO
}

export interface CategoryTreeWidgetOverrides {
  allCategoriesListDynamic?: string
}

const CategoryTreeWidget: FC<CategoryTreeWidgetProps> = ({
  overrides,
  category,
  categoriesPath
}) => {
  return category.subCategories?.length ? (
    <AllCAtegoriesList
      categories={category.subCategories}
      categoriesUrlPath={categoriesPath}
      sectionId="category-page-all-categories"
      title={`${overrideText('Kategori', overrides?.allCategoriesListDynamic)} ${category.name}`}
    />
  ) : null
}

export default CategoryTreeWidget
