import type { FC, ReactElement } from 'react'
import type {
  AccordionItemProps,
  AccordionPanelProps,
  AccordionProps,
  ButtonProps
} from '@chakra-ui/react'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box
} from '@chakra-ui/react'
import GrandHeading from './GrandHeading'

interface IGrandAccordionProps extends AccordionProps {
  items: IAccordionItem[]
  buttonProps?: ButtonProps
  panelProps?: AccordionPanelProps
}

export interface IAccordionItem extends AccordionItemProps {
  id: string
  title: string
  component: ReactElement | null
  onClick?: () => void
  noArrow?: boolean
  buttonProps?: ButtonProps
}

const GrandAccordion: FC<IGrandAccordionProps> = ({
  items,
  buttonProps = null,
  panelProps = null,
  ...props
}) => {
  return (
    <Accordion {...props}>
      {items.map(
        ({
          id,
          title,
          component,
          onClick,
          noArrow,
          buttonProps: itemButtonProps,
          ...itemProps
        }) => (
          <AccordionItem key={id} {...itemProps}>
            <AccordionButton
              _focus={{
                outlineColor: 'none'
              }}
              aria-label={title}
              fontSize={{
                base: 'sm',
                md: 'lg'
              }}
              onClick={onClick}
              py={{
                base: 0,
                md: 'initial'
              }}
              {...itemButtonProps}
              {...buttonProps}>
              <Box flex="1" textAlign="left">
                <GrandHeading
                  color="inherit"
                  cursor="pointer"
                  fontSize="inherit"
                  fontWeight="inherit"
                  headingTag="h2"
                  title={title}
                />
              </Box>
              {!noArrow ? <AccordionIcon /> : null}
            </AccordionButton>
            <AccordionPanel pb={4} {...panelProps}>
              {component}
            </AccordionPanel>
          </AccordionItem>
        )
      )}
    </Accordion>
  )
}

export default GrandAccordion
