'use client'

import type { FC } from 'react'
import { Box } from '@chakra-ui/react'
import type { StorefrontWidget, EmbeddedVideoWidgetType } from 'ecosystem'
import { GrandSection } from 'ui'
import { type WidgetImplementation } from '../registry/typings'

type WidgetEmbeddedVideoProps = WidgetImplementation<StorefrontWidget<EmbeddedVideoWidgetType>>

const WidgetEmbeddedVideoSection: FC<WidgetEmbeddedVideoProps> = ({ widget, widgetId }) => {
  return (
    <GrandSection sectionId={widgetId}>
      <Box mb={4} mt={0} mx={-4} overflow="hidden">
        <Box
          css={`
              & iframe, object, embed {
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  left: 0;
                  top: 0;
              }

          ,`}
          h={0}
          overflow="hidden"
          pb="56.25%"
          position="relative"
          pt="30px">
          <iframe
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            src={widget.widget.videoUrl}
            title="Video"
          />
        </Box>
      </Box>
    </GrandSection>
  )
}

export default WidgetEmbeddedVideoSection
