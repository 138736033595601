import type { HeadingProps } from '@chakra-ui/react'
import {
  type BrandDTO,
  type CategoryDTO,
  type DbInfoFields,
  type DeliveryDTO,
  type OldStoreProductFilterDTO,
  type TagDTO
} from './grand-connect'
import type { Option } from './general'

export enum StorefrontWidgetTypeGeneral {
  WIDGET_BLOG = 'WIDGET_BLOG',
  WIDGET_BLOG_CATEGORY = 'WIDGET_BLOG_CATEGORY',
  WIDGET_STATIC_VIEW = 'WIDGET_STATIC_VIEW',
  WIDGET_STATIC_VIEW_CATEGORY = 'WIDGET_STATIC_VIEW_CATEGORY',
  FOOTER_SECTION = 'FOOTER_SECTION',
  CONTACT_FORM = 'CONTACT_FORM'
}

export enum StorefrontWidgetTypeThirdParty {
  HELLO_RETAIL = 'HELLO_RETAIL'
}

export enum StorefrontWidgetTypeHandlerEnum {
  HERO_CAROUSEL = 'HERO_CAROUSEL',
  CAMPAIGN_HERO = 'CAMPAIGN_HERO',
  CAMPAIGN_WIDGET = 'CAMPAIGN_WIDGET',
  WIDGET_CTA = 'WIDGET_CTA',
  IMAGE_GALLERY = 'IMAGE_GALLERY',
  IMAGE = 'IMAGE',
  WIDGET_RELATED_PRODUCTS = 'WIDGET_RELATED_PRODUCTS',
  WIDGET_TAG_PRODUCTS = 'WIDGET_TAG_PRODUCTS'
}

export enum WidgetSimpleIdTypeHandlerEnum {
  CATEGORY_TOOLBAR = 'CATEGORY_TOOLBAR',
  CATEGORY_TREE = 'CATEGORY_TREE',
  PRODUCT_LIST = 'PRODUCT_LIST',
  PRODUCT_LIST_1 = 'PRODUCT_LIST_1',
  WIDGET_VIDEO = 'WIDGET_VIDEO',
  WIDGET_EMBEDDED_VIDEO = 'WIDGET_EMBEDDED_VIDEO',
  GOOGLE_MAP_WIDGET = 'GOOGLE_MAP_WIDGET',
  DIVIDER_WIDGET = 'DIVIDER_WIDGET',
  JOINED_WIDGET = 'JOINED_WIDGET',
  CUSTOM_WIDGET_ID = 'CUSTOM_WIDGET_ID'
}

export enum WidgetGroupTypeEnum {
  SQUARE_LINK = 'SQUARE_LINK',
  BUBBLE_LINK = 'BUBBLE_LINK',
  CIRCLE_LINK = 'CIRCLE_LINK',
  CIRCLE_LINK_1 = 'CIRCLE_LINK_1',
  SUMMARY_BOXES_LINK = 'SUMMARY_BOXES_LINK',
  SUMMARY_BOXES_BUTTON = 'SUMMARY_BOXES_BUTTON',
  SUMMARY_BOXES_BUTTON_1 = 'SUMMARY_BOXES_BUTTON_1',
  SUMMARY_BOXES_BUTTON_2 = 'SUMMARY_BOXES_BUTTON_2',
  ARTICLE_SIZED_BOXES = 'ARTICLE_SIZED_BOXES',
  ARTICLE_BOXES = 'ARTICLE_BOXES',
  ARTICLE_BOXES_1 = 'ARTICLE_BOXES_1',
  ARTICLE_BOXES_2 = 'ARTICLE_BOXES_2',
  ACCORDION_ITEM = 'ACCORDION_ITEM',
  COLORED_ITEMS = 'COLORED_ITEMS',
  TWO_COLUMNS = 'TWO_COLUMNS'
}

export enum WidgetDynamicTypeEnum {
  HERO = 'HERO',
  CONTENT_BOX = 'CONTENT_BOX'
}

export enum CategoryWidgetDynamicTypeEnum {
  HERO = 'HERO',
  CONTENT_BOX = 'CONTENT_BOX'
}

export type StorefrontWidgetTypeHandler =
  | StorefrontWidgetTypeHandlerEnum
  | WidgetGroupTypeEnum
  | WidgetSimpleIdTypeHandlerEnum
  | WidgetDynamicTypeEnum

export const StorefrontWidgetTypeAll = {
  ...StorefrontWidgetTypeGeneral,
  ...StorefrontWidgetTypeThirdParty,
  ...StorefrontWidgetTypeHandlerEnum,
  ...WidgetSimpleIdTypeHandlerEnum,
  ...WidgetGroupTypeEnum,
  ...WidgetDynamicTypeEnum
} as const

// eslint-disable-next-line @typescript-eslint/no-unused-vars -- We needed to extract
const { WIDGET_BLOG, ...filteredGeneralWidgets } = StorefrontWidgetTypeGeneral

export const TranslationRestrictedWidgets = {
  ...filteredGeneralWidgets,
  ...StorefrontWidgetTypeThirdParty,
  ...WidgetSimpleIdTypeHandlerEnum,
  IMAGE_GALLERY: StorefrontWidgetTypeHandlerEnum.IMAGE_GALLERY,
  IMAGE: StorefrontWidgetTypeHandlerEnum.IMAGE
} as const

export type StorefrontWidgetTypeAll =
  (typeof StorefrontWidgetTypeAll)[keyof typeof StorefrontWidgetTypeAll]
export type WidgetDynamicType = keyof typeof WidgetDynamicTypeEnum
export type CategoryWidgetDynamicType = keyof typeof CategoryWidgetDynamicTypeEnum

export enum WidgetCategoryId {
  SUMMARY_BOXES = 'SUMMARY_BOXES',
  HEROES = 'HEROES',
  CARDS = 'CARDS',
  OTHER = 'OTHER',
  CONTENT = 'CONTENT',
  THIRD_PARTY = 'THIRD_PARTY',
  FORMS = 'FORMS',
  STATIC_PAGES = 'STATIC_PAGES',
  FOOTER = 'FOOTER',
  BLOG = 'BLOG',
  PRODUCT_LIST = 'PRODUCT_LIST',
  CATEGORY = 'CATEGORY',
  NON_EDITABLE = 'NON_EDITABLE'
}

export interface WidgetCategory {
  categoryId: WidgetCategoryId
  name: string
  description: string
  icon: any
}

export interface WidgetMeta {
  label: string
  icon: any
  description: string
  categoryId: WidgetCategoryId
}

export interface WidgetItem {
  type: StorefrontWidgetTypeAll
  meta: WidgetMeta
}

// -End of general widget types- //

export interface BasicWidgetType {
  id: string
  name: string
}

export type AnyWidget = Record<string, any>

export type StorefrontWidget<T extends AnyWidget = AnyWidget> = Partial<DbInfoFields> &
  WidgetIdentifiers & {
    id: string
    parentId: Option<string>
    storeId: string
    type: StorefrontWidgetTypeAll
    order: number
    widget: WidgetContent<T>
  }

export type WidgetContent<T extends AnyWidget> = BasicWidgetType & T

export type WidgetGroup<T extends AnyWidget = AnyWidget> = WithMobileDisplaySupport &
  WidgetStyling & {
    items?: WidgetGroupItem<T>[]
  }

export type WidgetGroupItem<T> = T & BasicWidgetGroupItem

export interface BasicWidgetGroupItem {
  id: string
  order: number
}

export type BasicStyledWidgetGroupItem = WidgetStyling & BasicWidgetGroupItem

export type StylesObject = Record<string, unknown>

export interface WidgetStyling {
  styling?: StylesObject
}

interface WithMobileDisplaySupport {
  showsOnMobile?: boolean
}

export interface WidgetIdentifiers {
  brandId: Option<string>
  categoryId: Option<string>
  tagId: Option<string>
  view: Option<string>
}

export const WidgetEndpointTags = ['CATEGORY', 'TAG', 'BRAND', 'VIEW'] as const
export type WidgetEndpointTagsType = (typeof WidgetEndpointTags)[number]

export interface StorefrontWidgetsByTypeResponse<T extends AnyWidget> {
  widgets: StorefrontWidget<T>[]
}

export type BasicWidgetItem = WidgetStyling & {
  id: string
  name: string
  pathObj?: PathObj
  imgUrl: string
  title?: string
  sub?: string
  tag?: string
  isReversed?: boolean
}

export interface CustomWidgetType {
  widgetId: string
}

export type BubbleLinkWidgetItemType = BasicStyledWidgetGroupItem & {
  title?: string
  pathObj?: PathObj
  isExternal?: boolean
}
export type BubbleLinkWidgetType = WidgetGroup<BubbleLinkWidgetItemType> &
  WidgetStyling & {
    title?: string
    description?: string
  }

export type CircleLinkWidgetItemType = BasicStyledWidgetGroupItem & {
  title?: string
  pathObj?: PathObj
  imgUrl?: string
  sub?: string
  tag?: string
  isExternal?: boolean
}
export type CircleLinkWidgetType = BasicWidgetLink<CircleLinkWidgetItemType>

export type SquareWidgetItemType = BasicStyledWidgetGroupItem & {
  title?: string
  pathObj?: PathObj
  imgUrl?: string
  sub?: string
  tag?: string
  isExternal?: boolean
}
export type SquareWidgetType = BasicWidgetLink<SquareWidgetItemType>

export type CampaignWidgetItemType = BasicWidgetGroupItem & {
  title: string
  pathObj: PathObj
  imgUrl: string
  isExternal?: boolean
}
export type CampaignWidgetType = {
  items: CampaignWidgetItemType[]
} & WidgetStyling &
  WithMobileDisplaySupport

export type CampaignHeroWidgetType = WidgetStyling &
  WithMobileDisplaySupport & {
    alt: string
    imgUrl: string
    pathObj?: PathObj
    sub: string
    title: string
    name: string
    mobileImgUrl: Option<string>
    isExternal?: boolean
    withAspectRatio: boolean
  }

export type ContentBoxWidgetType = WidgetStyling &
  WithMobileDisplaySupport & {
    content: Option<string>
    contentMode: 'AUTO' | 'CUSTOM'
    isWrapped?: boolean
  }

export type HeroWidgetType = WidgetStyling &
  WithMobileDisplaySupport & {
    path: string
    title: string
    sub: string
    alt: string
    imgUrl: string
    mobileImgUrl: Option<string>
    ribbonText?: string
    priority?: boolean
    cta?: {
      label: string
      pathObj: PathObj
      isExternal: boolean
    }
    headingTag: HeadingProps['as']
    slogan: string
    mobileLayout: 'DEFAULT' | 'COMPACT'
    titleMode: 'CUSTOM' | 'AUTO'
    subMode: 'CUSTOM' | 'AUTO'
    imageMode: 'CUSTOM' | 'AUTO'
    withAspectRatio?: boolean
  }

export type HeroCarouselWidgetPaginationTypes = 'bullets' | 'fraction' | 'progressbar' | 'custom'
export type HeroCarouselWidgetType = WidgetStyling &
  WithMobileDisplaySupport & {
    items: HeroCarouselWidgetItemType[]
    title?: string
    isWrapped?: boolean
    itemsAspectRatio?: Ratio
    itemsAspectRatioMobile?: Ratio

    /**
     * Number of slides per view (slides visible at the same time on slider's container).
     *
     * @defaultValue 1
     */
    slidesPerView?: number

    /** Pagination */
    pagination?: {
      enabled?: boolean

      /**
       * String with type of pagination. Can be `'bullets'`, `'fraction'`, `'progressbar'` or `'custom'`
       *
       * @defaultValue 'bullets'
       */
      type?: HeroCarouselWidgetPaginationTypes

      /**
       * Good to enable if you use bullets pagination with a lot of slides. So it will keep only few bullets visible at the same time.
       *
       * @defaultValue false
       */
      dynamicBullets?: boolean

      /**
       * The number of main bullets visible when `dynamicBullets` enabled.
       *
       * @defaultValue 1
       */
      dynamicMainBullets?: number

      /**
       * Toggle (hide/show) pagination container visibility after click on Slider's container
       *
       * @defaultValue true
       */
      hideOnClick?: boolean

      /**
       * If `true` then clicking on pagination button will cause transition to appropriate slide. Only for bullets pagination type
       *
       * @defaultValue false
       */
      clickable?: boolean
    }

    /** Show navigation buttons: next/prev slide */
    navigation?: boolean

    /** Set to true to enable continuous loop mode */
    loop?: boolean

    autoplay?: {
      enabled?: boolean

      /**
       * Delay between transitions (in ms). If this parameter is not specified, auto play will be disabled
       */
      delay?: number

      /**
       * Enable this parameter and autoplay will be stopped when it reaches last slide (has no effect in loop mode)
       */
      stopOnLastSlide?: boolean

      /**
       * Set to `false` and autoplay will not be disabled after user interactions (swipes),
       * it will be restarted every time after interaction
       *
       * @defaultValue true
       */
      disableOnInteraction?: boolean

      /**
       * Enables autoplay in reverse direction
       */
      reverseDirection?: boolean

      /**
       * When enabled autoplay will be paused on pointer (mouse) enter over Swiper container.
       */
      pauseOnMouseEnter?: boolean
    }
  }

export type HeroCarouselWidgetItemType = BasicStyledWidgetGroupItem & {
  imgUrl: string
  mobileImgUrl: Option<string>
  title?: string
  sub?: string
  alt: string
  priority?: boolean
  headingTag?: HeadingProps['as']
  slogan?: string
  pathObj?: PathObj
  isExternal?: boolean
  ctaLabel?: string
}

export type Ratio = '16:9' | '4:3' | '3:2' | '8:5' | '33:10' | '1:1'

export type BasicWidgetLink<T> = {
  items: T[]
  title?: string
  description?: string
  itemsAspectRatio?: Ratio
} & WidgetStyling &
  WithMobileDisplaySupport

export type WidgetLinkVariant = 'sm' | 'md' | 'lg' | 'xl'

export type WidgetLinkFull<T> = BasicWidgetLink<T> & {
  variant?: WidgetLinkVariant
}

export type WidgetSectionButton<T> = BasicWidgetLink<T> & {
  btnLabel?: string
  pathObj?: PathObj
  isExternal?: boolean
  itemDesktopMinWidth?: string
}

export type SummaryBoxesWidgetItemType = BasicStyledWidgetGroupItem & {
  title?: string
  pathObj?: PathObj
  imgUrl?: string
  sub?: string
  tag?: string
  isExternal?: boolean
  isReversed?: boolean
}
export type SummaryBoxesWidgetType = BasicWidgetLink<SummaryBoxesWidgetItemType>

export type SummaryBoxesButtonWidgetItemType = BasicStyledWidgetGroupItem & {
  title?: string
  pathObj?: PathObj
  imgUrl?: string
  sub?: string
  tag?: string
  isExternal?: boolean
  btnLabel?: string
}
export type SummaryBoxesButtonWidgetType = BasicWidgetLink<SummaryBoxesButtonWidgetItemType>

export type WidgetCTAWidgetType = WidgetStyling &
  WithMobileDisplaySupport & {
    text?: string
    sub?: string
    btnLabel: string
    pathObj: PathObj
    headingTag: string
    isExternal: boolean
  }

export type SimpleIdWidgetType = {
  widgetId: string
  [extraProp: string]: string | number | boolean
} & WithMobileDisplaySupport

export enum WidgetSingleViewId {
  STATIC_VIEWS = 'STATIC_VIEWS',
  LANDING = 'LANDING',
  FOOTER = 'FOOTER',
  BLOG = 'BLOG',
  SHARED_WIDGETS = 'SHARED_WIDGETS'
}

export enum WidgetDynamicViews {
  CATEGORY = 'CATEGORY',
  TAG = 'TAG',
  BRAND = 'BRAND'
}

export type WidgetDynamicViewId = (typeof WidgetDynamicViews)[keyof typeof WidgetDynamicViews]
export type WidgetViewId = WidgetSingleViewId | WidgetDynamicViewId

export interface ImageGalleryWidgetItemType {
  id: string
  order: number
  imgUrl: string
  alt: string
  rowSpan: number
  colSpan: number
  pathObj?: PathObj
  isExternal?: boolean
}

export type ImageGalleryWidgetType = WithMobileDisplaySupport &
  WidgetStyling & {
    items: ImageGalleryWidgetItemType[]
    templateColumns: number
    templateColumnsMobile: number
  }

export type ImageWidgetType = WidgetStyling &
  WithMobileDisplaySupport & {
    imgUrl: string
    title?: string
    sub?: string
    mobileImgUrl?: string
    aspectRatio: Ratio
    mobileAspectRatio?: Ratio
  }

export type AccordionWidgetItemType = BasicWidgetGroupItem & {
  htmlSnippet: string
  title?: string
}
export type AccordionWidgetType = WidgetGroup<AccordionWidgetItemType>

export type VideoWidgetType = WithMobileDisplaySupport & {
  videoUrl: string
}

export type EmbeddedVideoWidgetType = VideoWidgetType

export type MapWidgetType = WithMobileDisplaySupport &
  WidgetStyling & {
    title?: string
    description?: string
    lat: string
    lng: string
    sub?: string
    layout?: 'DEFAULT' | 'COMPACT'
  }

export interface FooterItem {
  name: string
  sectionTitle: string
  items: FooterSectionWidgetItemType[]
}

export type FooterSectionWidgetItemType = BasicWidgetGroupItem & {
  label?: string
  text?: string
  pathObj?: PathObj
  isExternal?: boolean
}

export type FooterSectionWidgetType = WidgetStyling & {
  sectionTitle: string
  items: FooterSectionWidgetItemType[]
}

export enum DynamicWidgetType {
  CATEGORY = 'CATEGORY',
  BRAND = 'BRAND',
  TAG = 'TAG'
}

export interface DynamicWidgetProps {
  viewId: string
  type: DynamicWidgetType
  filterConfig: OldStoreProductFilterDTO
  deliveryOptions: Option<DeliveryDTO[]>
  obj: CategoryDTO | TagDTO | BrandDTO
}

export type ArticleBoxesWidgetType = BasicWidgetLink<ArticleBoxesWidgetItemType> & {
  pathObj?: PathObj
  btnLabel?: string
  isExternal?: boolean
  itemDesktopMinWidth?: string
}
export type ArticleBoxesWidgetItemType = BasicStyledWidgetGroupItem & {
  pathObj?: PathObj
  imgUrl?: string
  title?: string
  sub?: string
  label?: string
  isExternal?: boolean
}

export type ArticleSizedBoxesWidgetType = WidgetLinkFull<ArticleSizedBoxesWidgetItemType> & {
  path?: string
  btnLabel?: string
  isExternal?: boolean
}

export type ArticleSizedBoxesWidgetItemType = BasicStyledWidgetGroupItem & {
  imgUrl?: string
  pathObj?: PathObj
  sub?: string
  title?: string
  subtitle?: string
  showSubOnMobile?: boolean
  isExternal?: boolean
  label?: string
}

export type ColoredItemsWidgetType = WidgetLinkFull<ColoredItemsWidgetItemType>

export type ColoredItemsWidgetItemType = BasicStyledWidgetGroupItem & {
  title: string
  pathObj?: PathObj
  imgUrl?: string
  isExternal?: boolean
  color: string
}

export type DividerWidgetType = WidgetStyling & WithMobileDisplaySupport

export type TwoColumnsWidgetType = WidgetStyling &
  WithMobileDisplaySupport & {
    items?: TwoColumnsWidgetItemType[]
    title?: string
    leftColumnContent?: string
    rightColumnContent?: string
    pathObj?: PathObj
    isExternal?: boolean
    btnLabel?: string
  }

export type TwoColumnsWidgetItemType = BasicWidgetGroupItem & {
  title?: string
  content?: string
}

export type JoinedWidgetType<RelatedSharedWidgetType = StorefrontWidget> = BasicWidgetType &
  WidgetStyling &
  WithMobileDisplaySupport & {
    title?: string
    relatedSharedWidgets: RelatedSharedWidgetType[]
    columnsNumber?: string
    columnsNumberMobile?: string
    isWrapped?: boolean
  }

export interface RelatedWidget {
  id: string
  name: string
  imgUrl: string
  slug?: string
}

interface WidgetBasicCategory {
  id: string
  name: string
  title: string
  description: string
  order?: number
}

export interface WidgetBlog {
  tag: string
  imgUrl: string
  title: string
  alt: string
  summary: string
  date: string
  author: string
  relatedProducts: RelatedWidget[]
  slug: string
  content: string
}

export type WidgetBlogCategory = WidgetBasicCategory & {
  relatedBlogPosts: RelatedWidget[]
}

export interface WidgetStaticPage {
  id: string
  name: string
  title: string
  description: string
  viewId: string
  slug: string
  imgUrl: string
}

export type WidgetStaticPageCategory = WidgetBasicCategory & {
  relatedStaticPages: RelatedWidget[]
}

export interface WidgetRelatedProducts {
  title: string
  relatedProducts: RelatedWidget[]
  pathObj?: PathObj
}

export type PathType = 'tag' | 'brand' | 'category' | 'blog' | 'custom'

export interface PathObj {
  id: string
  type: PathType
  slug: string
}

export interface WidgetTagProducts {
  title: string
  pathObj: PathObj
}
