'use client'
import { Flex, Grid, GridItem, SimpleGrid, VStack } from '@chakra-ui/react'
import type { ArticleSizedBoxesWidgetType, StorefrontWidget } from 'ecosystem'
import { GrandNextLink, GrandSection, GrandText, ScrollDragContainer } from 'ui'
import { WIDGET_GROUP_CN } from '../../constants'
import { type WidgetImplementation } from '../../registry/typings'
import { articleSizedBoxesCn as cn } from './constants'
import ArticleSizedBoxesWidgetBaseItem from './ArticleSizedBoxesWidgetBaseItem'
import ArticleSizedBoxesWidgetLargeItem from './ArticleSizedBoxesWidgetLargeItem'

type SummaryBoxesButton1WidgetProps = WidgetImplementation<
  StorefrontWidget<ArticleSizedBoxesWidgetType>
>

const ArticleSizedBoxesWidget = ({
  widget,
  widgetId,
  ...props
}: SummaryBoxesButton1WidgetProps) => {
  const { variant = 'md' } = widget.widget

  const renderItems = () => {
    switch (variant) {
      case 'sm': {
        return (
          <ScrollDragContainer cursor="default">
            <Grid
              className={cn(WIDGET_GROUP_CN.WRAPPER)}
              gap={{
                base: 3,
                md: 5
              }}
              templateColumns={`repeat(${widget.widget.items.length}, minmax(300px, 1fr))`}>
              {widget.widget.items.map((item) => (
                <GridItem colSpan={1} display="flex" key={item.id}>
                  <ArticleSizedBoxesWidgetBaseItem
                    aspectRatio={widget.widget.itemsAspectRatio}
                    flex="1"
                    mobileAspectRatio="4:3"
                    {...item}
                  />
                </GridItem>
              ))}
            </Grid>
          </ScrollDragContainer>
        )
      }

      case 'md': {
        return (
          <SimpleGrid
            className={cn(WIDGET_GROUP_CN.WRAPPER)}
            columns={2}
            spacing={{
              base: 2,
              md: 5
            }}>
            {widget.widget.items.map((item) => (
              <GridItem colSpan={1} display="flex" key={item.id}>
                <ArticleSizedBoxesWidgetBaseItem
                  aspectRatio={widget.widget.itemsAspectRatio}
                  flex="1"
                  mobileAspectRatio="4:3"
                  {...item}
                />
              </GridItem>
            ))}
          </SimpleGrid>
        )
      }

      case 'lg': {
        return (
          <VStack className={cn(WIDGET_GROUP_CN.WRAPPER)} spacing={10}>
            {widget.widget.items.map((item) => (
              <ArticleSizedBoxesWidgetLargeItem
                aspectRatio={widget.widget.itemsAspectRatio}
                flex={1}
                key={item.id}
                mobileAspectRatio="16:9"
                {...item}
              />
            ))}
          </VStack>
        )
      }

      case 'xl': {
        return (
          <VStack className={cn(WIDGET_GROUP_CN.WRAPPER)} spacing={10}>
            {widget.widget.items.map((item) => (
              <ArticleSizedBoxesWidgetBaseItem
                aspectRatio={widget.widget.itemsAspectRatio}
                flex="1"
                fontSize="lg"
                key={item.id}
                mobileAspectRatio="4:3"
                titleProps={{
                  fontSize: '2xl'
                }}
                {...item}
              />
            ))}
          </VStack>
        )
      }
    }
  }

  return (
    <GrandSection
      className={cn(WIDGET_GROUP_CN.SECTION)}
      contentContainerProps={{
        className: cn(WIDGET_GROUP_CN.SECTION_CONTAINER)
      }}
      sectionId={widgetId}
      title={widget.widget.title}
      titleProps={{
        className: cn(WIDGET_GROUP_CN.SECTION_TITLE),
        containerProps: {
          className: cn(WIDGET_GROUP_CN.SECTION_TITLE_CONTAINER)
        },
        decorationProps: {
          className: cn(WIDGET_GROUP_CN.SECTION_TITLE_DECORATION)
        }
      }}
      {...props}>
      {widget.widget.description ? (
        <GrandText className={cn(WIDGET_GROUP_CN.SECTION_DESC)}>
          {widget.widget.description}
        </GrandText>
      ) : null}

      {renderItems()}

      {widget.widget.btnLabel && widget.widget.path ? (
        <Flex className={cn(WIDGET_GROUP_CN.SECTION_LINK_WRAPPER)} w="full">
          <GrandNextLink
            aria-label={widget.widget.btnLabel}
            href={widget.widget.path}
            isButton
            isExternal={widget.widget.isExternal}
            minW="220px">
            {widget.widget.btnLabel}
          </GrandNextLink>
        </Flex>
      ) : null}
    </GrandSection>
  )
}

export default ArticleSizedBoxesWidget
