import React, { cloneElement, memo, type ReactNode } from 'react'
import {
  Modal,
  ModalBody,
  type ModalBodyProps,
  ModalCloseButton,
  ModalContent,
  type ModalContentProps,
  ModalHeader,
  ModalOverlay,
  type ModalProps,
  useDisclosure
} from '@chakra-ui/react'
import { BiCollapseAlt, BiExpandAlt } from 'react-icons/bi'
import GrandIconButton from './GrandIconButton'

interface LocalModalProps extends Partial<ModalProps> {
  trigger: ReactNode
  title?: string
  children: ReactNode
  hideCloseButton?: boolean
  onOpen: () => void
  onClose: () => void
  isOpen: boolean
  contentProps?: ModalContentProps
  bodyProps?: ModalBodyProps
  expandable?: boolean
}

const LocalModalComponent: React.FC<LocalModalProps> = ({
  hideCloseButton,
  trigger,
  title,
  children,
  onClose,
  onOpen,
  isOpen,
  contentProps,
  bodyProps,
  size,
  expandable,
  ...props
}) => {
  const { isOpen: isExpanded, onToggle } = useDisclosure()
  const Trigger = cloneElement(trigger as React.ReactElement, {
    onClick: (e: any) => {
      e.stopPropagation()
      onOpen()
    }
  })

  return (
    <>
      {Trigger}

      <Modal
        motionPreset="scale"
        scrollBehavior="inside"
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        size={isExpanded ? 'full' : size}
        {...props}>
        <ModalOverlay />
        <ModalContent {...contentProps}>
          {!!title && (
            <ModalHeader fontSize="sm" textAlign="center">
              {title}
            </ModalHeader>
          )}

          {expandable && (
            <GrandIconButton
              tabIndex={-1}
              color="inherit"
              position="absolute"
              top={2}
              right={hideCloseButton ? 3 : 12}
              aria-label={isExpanded ? 'Shrink' : 'Expand'}
              fontSize="md"
              icon={isExpanded ? <BiCollapseAlt /> : <BiExpandAlt />}
              minW={undefined}
              onClick={onToggle}
              shadow="none"
              tooltip={isExpanded ? 'Shrink' : 'Expand'}
              variant="ghost"
              size="sm"
              borderRadius="md"
              zIndex={1}
              _hover={{
                bg: 'blackAlpha.100'
              }}
            />
          )}

          {!hideCloseButton && (
            <ModalCloseButton aria-label="close" _focus={{ outline: 'none' }} zIndex={1} />
          )}
          <ModalBody
            mx="auto"
            maxW={isExpanded ? '100%' : 'container.xl'}
            w="full"
            display="flex"
            alignItems="stretch"
            flex={1}
            flexDirection="column"
            zIndex={0}
            {...bodyProps}>
            {children}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
const LocalModal = memo(LocalModalComponent)

export default LocalModal
