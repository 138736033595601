import type { Language, OldProductDTO } from 'ecosystem'
import { useMemo } from 'react'
import { getFullLocale } from 'shared-utils/utils/locales'
import { Box, useColorMode } from '@chakra-ui/react'
import type { IngridPDPWidgetConfig } from './types'
import { IngridPDPWidgetOriginal } from './IngridPDPWidgetOriginal'
import { generateIngridCart, generateIngridItemCartFromProduct } from './utils'

const INGRID_PDP_TOKEN = process.env.NEXT_PUBLIC_INGRID_PDP_KEY ?? ''

interface IngridPDPWidgetProps {
  config: { locale: Language } & Pick<IngridPDPWidgetConfig, 'country' | 'currency'>
  product: OldProductDTO
  quantity?: number
  totalPrice?: number
}

export const IngridPDPWidget = ({
  config: { locale, country, currency },
  product,
  quantity,
  totalPrice
}: IngridPDPWidgetProps) => {
  const { colorMode } = useColorMode()

  const config = useMemo<IngridPDPWidgetConfig>(() => {
    const item = generateIngridItemCartFromProduct(product, quantity, totalPrice)

    return {
      auth_token: INGRID_PDP_TOKEN,
      country,
      currency,
      locales: [getFullLocale(locale)],
      // cart doesn't affect to the result
      cart: generateIngridCart([]),
      // only viewed_item price affects
      viewed_item: item
    }
  }, [country, currency, locale, product, quantity, totalPrice])

  // workaround with colorScheme, because with 'colorScheme': 'dark' background of Ingrid Widget iframe would be white
  return (
    <Box
      {...(colorMode === 'dark'
        ? { backgroundColor: 'primary.200', px: 2, sx: { colorScheme: 'light' } }
        : {})}
      w="100%">
      <IngridPDPWidgetOriginal config={config} />
    </Box>
  )
}
