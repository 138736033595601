'use client'

import { Flex, Grid, GridItem, SimpleGrid } from '@chakra-ui/react'
import type { StorefrontWidget, TwoColumnsWidgetType } from 'ecosystem'
import { GrandNextLink, GrandSection, HTMLArticle, ScrollDragContainer } from 'ui'
import { hrefFromAppPaths } from 'shared-utils'
import { useAppPaths } from 'ui/hooks'
import { type WidgetImplementation } from '../../registry/typings'
import TwoColumnsWidgetItem from './TwoColumnsWidgetItem'
import { TWO_COLUMNS_WIDGET_CN as WIDGET_CN, twoColumnsCn as cn } from './constants'

type TwoColumnsWidgetProps = WidgetImplementation<StorefrontWidget<TwoColumnsWidgetType>>

const TwoColumnsWidget = ({ widget, widgetId, ...props }: TwoColumnsWidgetProps) => {
  const { title, items, leftColumnContent, rightColumnContent, pathObj, isExternal, btnLabel } =
    widget.widget
  const appPaths = useAppPaths()

  return (
    <GrandSection
      className={cn(WIDGET_CN.SECTION)}
      contentContainerProps={{
        className: cn(WIDGET_CN.SECTION_CONTAINER),
        spacing: 0,
        gap: 10
      }}
      sectionId={widgetId}
      titleProps={{
        title,
        containerProps: {
          className: cn(WIDGET_CN.TITLE)
        },
        decorationProps: {
          className: cn(WIDGET_CN.TITLE_DECORATION)
        }
      }}
      {...props}>
      <SimpleGrid
        columns={{
          base: 1,
          md: 3
        }}
        gap={12}>
        <GridItem
          alignItems="flex-start"
          as={Flex}
          className={cn(WIDGET_CN.LEFT_COLUMN)}
          colSpan={{
            base: 1,
            md: 2
          }}
          flexDirection="column"
          gap={10}>
          {leftColumnContent ? (
            <HTMLArticle
              className={cn(WIDGET_CN.LEFT_COLUMN_CONTENT)}
              content={leftColumnContent}
            />
          ) : null}

          {btnLabel && pathObj ? (
            <GrandNextLink
              className={cn(WIDGET_CN.LINK_LABEL)}
              aria-label={btnLabel}
              bg="brand.blue"
              href={hrefFromAppPaths(pathObj, appPaths)}
              isButton
              isExternal={isExternal}>
              {btnLabel}
            </GrandNextLink>
          ) : null}

          {Boolean(items?.length) && (
            <ScrollDragContainer mx={-3} px={3} width="calc(100% + 1.5rem)">
              <Grid
                gap={{
                  base: 3,
                  md: 5
                }}
                templateColumns={`repeat(${items?.length || 0}, 187px)`}>
                {items?.map((item) => <TwoColumnsWidgetItem key={item.id} {...item} />)}
              </Grid>
            </ScrollDragContainer>
          )}
        </GridItem>

        {rightColumnContent ? (
          <GridItem className={cn(WIDGET_CN.RIGHT_COLUMN)} colSpan={1}>
            <HTMLArticle
              className={cn(WIDGET_CN.RIGHT_COLUMN_CONTENT)}
              content={rightColumnContent}
            />
          </GridItem>
        ) : null}
      </SimpleGrid>
    </GrandSection>
  )
}

export default TwoColumnsWidget
