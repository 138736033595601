'use client'

import { Flex, Grid, GridItem, VStack } from '@chakra-ui/react'
import GoogleMap from 'google-maps-react-markers'
import { GrandAspectRatio, GrandHeading, GrandSection, HTMLArticle } from 'ui'
import type { MapWidgetType, StorefrontWidget } from 'ecosystem'
import { type WidgetImplementation } from '../../registry/typings'
import { MAP_WIDGET_CN, mapWidgetCn as cn } from './constants'
import PinMarker from './PinMarker'

export type MapWidgetProps = WidgetImplementation<StorefrontWidget<MapWidgetType>>

/*
 * Since the npm package "google-map-react" has an open issue about the marker not following the map,
 *  we're using a fork. TODO switch back to google-map-react when this issue is solved
 *  https://github.com/google-map-react/google-map-react/issues/1189
 */
export function MapWidget({ widget, widgetId }: MapWidgetProps) {
  const { title, description, lat, lng, sub, layout } = widget.widget

  const renderMap = () => (
    <GoogleMap
      apiKey={process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY || ''}
      defaultCenter={{
        lat: Number(lat),
        lng: Number(lng)
      }}
      defaultZoom={18}
      options={{
        mapTypeControl: false,
        zoomControl: false,
        rotateControl: false,
        scaleControl: false,
        mapTypeId: 'roadmap'
      }}>
      <PinMarker lat={Number(lat)} lng={Number(lng)} />
    </GoogleMap>
  )

  const renderLayout = () => {
    switch (layout) {
      case 'COMPACT': {
        return (
          <Grid
            gap={{
              base: 5,
              md: 10
            }}
            templateColumns={{
              base: '1fr',
              md: 'repeat(10, 1fr)'
            }}>
            <GridItem
              colSpan={{
                base: 1,
                md: 10
              }}
              order={{
                base: 2,
                md: 1
              }}
              overflow="hidden"
              rounded="md">
              <GrandAspectRatio breakpoints={{ base: '16:9' }}>{renderMap()}</GrandAspectRatio>
            </GridItem>

            {description ? (
              <GridItem
                as={Flex}
                className={cn(MAP_WIDGET_CN.DESC)}
                colSpan={{
                  base: 1,
                  md: sub ? 6 : 10
                }}
                order={{
                  base: 1,
                  md: 2
                }}
                overflow="hidden"
                rounded="md">
                {description}
              </GridItem>
            ) : null}

            {sub ? (
              <GridItem
                as={Flex}
                className={cn(MAP_WIDGET_CN.CONTENT)}
                colSpan={{
                  base: 1,
                  md: description ? 4 : 10
                }}
                order={3}>
                <HTMLArticle content={sub} w="full" />
              </GridItem>
            ) : null}
          </Grid>
        )
      }
      default:
        return (
          <Grid
            gap={{
              base: 5,
              md: 10
            }}
            templateColumns={{
              base: '1fr',
              md: 'repeat(10, 1fr)'
            }}
            templateRows={{
              base: '1fr',
              md: 'repeat(2, 1fr)'
            }}>
            <GridItem
              colSpan={{
                base: 1,
                md: 6
              }}
              order={{
                base: 2,
                md: 1
              }}
              overflow="hidden"
              rounded="md"
              rowSpan={{
                base: 1,
                md: 2
              }}>
              <GrandAspectRatio breakpoints={{ base: '16:9', md: '4:3' }}>
                {renderMap()}
              </GrandAspectRatio>
            </GridItem>

            {description ? (
              <GridItem
                alignItems="center"
                as={Flex}
                className={cn(MAP_WIDGET_CN.DESC)}
                colSpan={{
                  base: 1,
                  md: 4
                }}
                order={{
                  base: 1,
                  md: 2
                }}
                rowSpan={sub ? 1 : { base: 1, md: 2 }}>
                {description}
              </GridItem>
            ) : null}

            {sub ? (
              <GridItem
                alignItems="center"
                as={Flex}
                className={cn(MAP_WIDGET_CN.CONTENT)}
                colSpan={{
                  base: 1,
                  md: 4
                }}
                order={3}
                rowSpan={description ? 1 : { base: 1, md: 2 }}>
                <HTMLArticle content={sub} w="full" />
              </GridItem>
            ) : null}
          </Grid>
        )
    }
  }

  return (
    <GrandSection
      className={cn(MAP_WIDGET_CN.SECTION)}
      contentContainerProps={{
        className: cn(MAP_WIDGET_CN.SECTION_CONTAINER)
      }}
      sectionId={widgetId}>
      <VStack alignItems="stretch" className={cn(MAP_WIDGET_CN.WRAPPER)} spacing={3}>
        {title ? <GrandHeading className={cn(MAP_WIDGET_CN.TITLE)} title={title} /> : null}

        {renderLayout()}
      </VStack>
    </GrandSection>
  )
}
