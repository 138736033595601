'use client'
import { VStack } from '@chakra-ui/react'
import type { StorefrontWidget, SummaryBoxesButtonWidgetType } from 'ecosystem'
import { GrandSection, GrandText } from 'ui'
import { WIDGET_GROUP_CN } from '../../constants'
import { type WidgetImplementation } from '../../registry/typings'
import { summaryBoxesButtonCn as cn } from './constants'
import SummaryBoxesButton2WidgetItem from './SummaryBoxesButton2WidgetItem'

type SummaryBoxesButtonWidgetProps = WidgetImplementation<
  StorefrontWidget<SummaryBoxesButtonWidgetType>
>

const SummaryBoxesButton2Widget = ({
  widget,
  widgetId,
  ...props
}: SummaryBoxesButtonWidgetProps) => {
  return (
    <GrandSection
      className={cn(WIDGET_GROUP_CN.SECTION)}
      contentContainerProps={{
        className: cn(WIDGET_GROUP_CN.SECTION_CONTAINER)
      }}
      sectionId={widgetId}
      title={widget.widget.title}
      titleProps={{
        className: cn(WIDGET_GROUP_CN.SECTION_TITLE),
        containerProps: {
          className: cn(WIDGET_GROUP_CN.SECTION_TITLE_CONTAINER)
        },
        decorationProps: {
          className: cn(WIDGET_GROUP_CN.SECTION_TITLE_DECORATION)
        }
      }}
      {...props}>
      {widget.widget.description ? (
        <GrandText className={cn(WIDGET_GROUP_CN.SECTION_DESC)}>
          {widget.widget.description}
        </GrandText>
      ) : null}

      <VStack className={cn(WIDGET_GROUP_CN.WRAPPER)} spacing={10}>
        {widget.widget.items.map((item) => (
          <SummaryBoxesButton2WidgetItem
            aspectRatio={widget.widget.itemsAspectRatio}
            key={item.id}
            {...item}
          />
        ))}
      </VStack>
    </GrandSection>
  )
}

export default SummaryBoxesButton2Widget
