'use client'
import { Flex, VStack } from '@chakra-ui/react'
import type { ArticleBoxesWidgetType, StorefrontWidget } from 'ecosystem'
import { GrandNextLink, GrandSection, GrandText } from 'ui'
import { hrefFromAppPaths } from 'shared-utils'
import { useAppPaths } from 'ui/hooks'
import { WIDGET_GROUP_CN } from '../../constants'
import { type WidgetImplementation } from '../../registry/typings'
import { articleBoxesCn as cn } from './constants'
import ArticleBoxesWidgetItem from './ArticleBoxesWidgetItem'

type ArticleBoxesWidgetProps = WidgetImplementation<StorefrontWidget<ArticleBoxesWidgetType>>

const ArticleBoxesWidget = ({ widget, widgetId, ...props }: ArticleBoxesWidgetProps) => {
  const appPaths = useAppPaths()

  return (
    <GrandSection
      className={cn(WIDGET_GROUP_CN.SECTION)}
      contentContainerProps={{
        className: cn(WIDGET_GROUP_CN.SECTION_CONTAINER)
      }}
      sectionId={widgetId}
      title={widget.widget.title}
      titleProps={{
        className: cn(WIDGET_GROUP_CN.SECTION_TITLE),
        containerProps: {
          className: cn(WIDGET_GROUP_CN.SECTION_TITLE_CONTAINER)
        },
        decorationProps: {
          className: cn(WIDGET_GROUP_CN.SECTION_TITLE_DECORATION)
        }
      }}
      {...props}>
      {widget.widget.description ? (
        <GrandText className={cn(WIDGET_GROUP_CN.SECTION_DESC)}>
          {widget.widget.description}
        </GrandText>
      ) : null}

      <VStack className={cn(WIDGET_GROUP_CN.WRAPPER)} spacing={5}>
        {widget.widget.items.map((item) => (
          <ArticleBoxesWidgetItem
            aspectRatio={widget.widget.itemsAspectRatio}
            key={item.id}
            {...item}
          />
        ))}
      </VStack>

      {widget.widget.btnLabel && widget.widget.pathObj ? (
        <Flex
          className={cn(WIDGET_GROUP_CN.SECTION_LINK_WRAPPER)}
          justifyContent="flex-end"
          w="full">
          <GrandNextLink
            aria-label={widget.widget.btnLabel}
            href={hrefFromAppPaths(widget.widget.pathObj, appPaths)}
            isButton
            isExternal={widget.widget.isExternal}
            minW="220px">
            {widget.widget.btnLabel}
          </GrandNextLink>
        </Flex>
      ) : null}
    </GrandSection>
  )
}

export default ArticleBoxesWidget
