import React from 'react'
import { Radio, RadioGroup, Stack } from '@chakra-ui/react'
import { type Option } from 'ecosystem'

export interface GrandRadioGroupItem {
  id: string
  label: string
}

export interface GrandRadioGroupProps {
  items: GrandRadioGroupItem[]
  selectedId?: Option<string>
  onSelect: (selectedId: string) => void
  isDisabled?: boolean
}

const GrandRadioGroup: React.FC<GrandRadioGroupProps> = ({
  selectedId,
  items,
  onSelect,
  isDisabled
}) => {
  const handleChange = (value: string) => {
    onSelect(value)
  }

  return (
    <RadioGroup
      isDisabled={isDisabled}
      onChange={handleChange}
      value={selectedId?.toString() || ''}>
      <Stack direction="column">
        {items.map((item) => (
          <Radio key={item.id} value={item.id.toString()}>
            {item.label}
          </Radio>
        ))}
      </Stack>
    </RadioGroup>
  )
}

export default GrandRadioGroup
