import type {
  AccordionWidgetType,
  ArticleBoxesWidgetType,
  ArticleSizedBoxesWidgetType,
  BubbleLinkWidgetType,
  CampaignHeroWidgetType,
  CampaignWidgetType,
  CircleLinkWidgetType,
  ColoredItemsWidgetType,
  ContentBoxWidgetType,
  DividerWidgetType,
  EmbeddedVideoWidgetType,
  HeroCarouselWidgetType,
  HeroWidgetType,
  ImageGalleryWidgetType,
  ImageWidgetType,
  JoinedWidgetType,
  MapWidgetType,
  SquareWidgetType,
  StorefrontWidget,
  StorefrontWidgetTypeHandler,
  SummaryBoxesButtonWidgetType,
  SummaryBoxesWidgetType,
  TwoColumnsWidgetType,
  VideoWidgetType,
  WidgetCTAWidgetType
} from 'ecosystem'
import type { ReactElement } from 'react'
import {
  BubbleLinkWidget,
  CircleLinkWidgetHandler,
  HeroCarouselWidget,
  SummaryBoxesWidgetHandler
} from '../components'
import HeroWidget from '../components/HeroWidget'
import CampaignHeroWidget from '../components/CampaignHeroWidget'
import WideWidgetHandler from '../components/WideWidget/WideWidgetHandler'
import TwoColumnsWidget from '../components/TwoColumnsWidget'
import WidgetCTA from '../components/WidgetCTA'
import ContentBox from '../components/ContentBox'
import WidgetImageGallery from '../components/WidgetImageGallery/WidgetImageGallery'
import ImageWidget from '../components/ImageWidget'
import WidgetAccordionItem from '../components/WidgetAccordionItem'
import WidgetVideoSection from '../components/WidgetVideoSection'
import WidgetEmbeddedVideoSection from '../components/WidgetEmbeddedVideoSection'
import { MapWidget } from '../components/MapWidget'
import CircleLink1Widget from '../components/CircleLink1Widget'
import SummaryBoxesButtonWidget from '../components/SummaryBoxesButtonWidget'
import SummaryBoxesButton1Widget from '../components/SummaryBoxesButton1Widget'
import SummaryBoxesButton2Widget from '../components/SummaryBoxesButton2Widget'
import ArticleBoxesWidget from '../components/ArticleBoxesWidget'
import ArticleBoxes1Widget from '../components/ArticleBoxes1Widget'
import ArticleBoxes2Widget from '../components/ArticleBoxes2Widget'
import ColoredItemsWidget from '../components/ColoredItemsWidget'
import DividerWidget from '../components/DividerWidget'
import PlaceholderWidget from '../components/PlaceholderWidget'
import JoinedWidget from '../components/JoinedWidget'
import ArticleSizedBoxesWidget from '../components/ArticleSizedBoxesWidget/ArticleSizedBoxesWidget'
import { type WidgetFactoryOptions } from './typings'

export const storefrontWidgetRegistry = <Extensions extends string | number | symbol>(
  widget: StorefrontWidget,
  opts: WidgetFactoryOptions<Extensions>
) => {
  const dictionary: Record<StorefrontWidgetTypeHandler, ReactElement> = {
    HERO: (
      // This component will work with aspect ratio and with no aspect ratio
      <HeroWidget
        widget={widget as StorefrontWidget<HeroWidgetType>}
        widgetId={widget.widget.name}
      />
    ),

    HERO_CAROUSEL: (
      <HeroCarouselWidget
        widget={widget as StorefrontWidget<HeroCarouselWidgetType>}
        widgetId={widget.widget.name}
      />
    ),

    CAMPAIGN_HERO: (
      // This component will work with aspect ratio and with no aspect ratio
      <CampaignHeroWidget
        widget={widget as StorefrontWidget<CampaignHeroWidgetType>}
        widgetId={widget.widget.name}
      />
    ),

    CAMPAIGN_WIDGET: (
      <WideWidgetHandler
        priority
        widget={widget as StorefrontWidget<CampaignWidgetType>}
        widgetId={widget.widget.name}
      />
    ),

    SQUARE_LINK: (
      <WideWidgetHandler
        widget={widget as StorefrontWidget<SquareWidgetType>}
        widgetId={widget.widget.name}
      />
    ),

    BUBBLE_LINK: (
      <BubbleLinkWidget
        widget={widget as StorefrontWidget<BubbleLinkWidgetType>}
        widgetId={widget.widget.name}
      />
    ),

    CIRCLE_LINK: (
      <CircleLinkWidgetHandler
        widget={widget as StorefrontWidget<CircleLinkWidgetType>}
        widgetId={widget.widget.name}
      />
    ),

    SUMMARY_BOXES_LINK: (
      <SummaryBoxesWidgetHandler
        widget={widget as StorefrontWidget<SummaryBoxesWidgetType>}
        widgetId={widget.widget.name}
      />
    ),

    WIDGET_CTA: (
      <WidgetCTA
        widget={widget as StorefrontWidget<WidgetCTAWidgetType>}
        widgetId={widget.widget.name}
      />
    ),

    CONTENT_BOX: (
      <ContentBox
        widget={widget as StorefrontWidget<ContentBoxWidgetType>}
        widgetId={widget.widget.name}
      />
    ),

    IMAGE_GALLERY: (
      <WidgetImageGallery
        widget={widget as StorefrontWidget<ImageGalleryWidgetType>}
        widgetId={widget.widget.name}
      />
    ),

    IMAGE: (
      <ImageWidget
        widget={widget as StorefrontWidget<ImageWidgetType>}
        widgetId={widget.widget.name}
      />
    ),

    ACCORDION_ITEM: (
      <WidgetAccordionItem
        widget={widget as StorefrontWidget<AccordionWidgetType>}
        widgetId={widget.widget.name}
      />
    ),

    WIDGET_VIDEO: (
      <WidgetVideoSection
        widget={widget as StorefrontWidget<VideoWidgetType>}
        widgetId={widget.widget.name}
      />
    ),

    WIDGET_EMBEDDED_VIDEO: (
      <WidgetEmbeddedVideoSection
        widget={widget as StorefrontWidget<EmbeddedVideoWidgetType>}
        widgetId={widget.widget.name}
      />
    ),

    GOOGLE_MAP_WIDGET: (
      <MapWidget widget={widget as StorefrontWidget<MapWidgetType>} widgetId={widget.widget.name} />
    ),

    // From Stenlunds
    CIRCLE_LINK_1: (
      <CircleLink1Widget
        widget={widget as StorefrontWidget<CircleLinkWidgetType>}
        widgetId={widget.widget.name}
      />
    ),

    SUMMARY_BOXES_BUTTON: (
      <SummaryBoxesButtonWidget
        widget={widget as StorefrontWidget<SummaryBoxesButtonWidgetType>}
        widgetId={widget.widget.name}
      />
    ),

    SUMMARY_BOXES_BUTTON_1: (
      <SummaryBoxesButton1Widget
        widget={widget as StorefrontWidget<SummaryBoxesButtonWidgetType>}
        widgetId={widget.widget.name}
      />
    ),

    SUMMARY_BOXES_BUTTON_2: (
      <SummaryBoxesButton2Widget
        widget={widget as StorefrontWidget<SummaryBoxesButtonWidgetType>}
        widgetId={widget.widget.name}
      />
    ),

    ARTICLE_SIZED_BOXES: (
      <ArticleSizedBoxesWidget
        widget={widget as StorefrontWidget<ArticleSizedBoxesWidgetType>}
        widgetId={widget.widget.name}
      />
    ),

    ARTICLE_BOXES: (
      <ArticleBoxesWidget
        widget={widget as StorefrontWidget<ArticleBoxesWidgetType>}
        widgetId={widget.widget.name}
      />
    ),

    ARTICLE_BOXES_1: (
      <ArticleBoxes1Widget
        widget={widget as StorefrontWidget<ArticleBoxesWidgetType>}
        widgetId={widget.widget.name}
      />
    ),

    ARTICLE_BOXES_2: (
      <ArticleBoxes2Widget
        widget={widget as StorefrontWidget<ArticleBoxesWidgetType>}
        widgetId={widget.widget.name}
      />
    ),

    COLORED_ITEMS: (
      <ColoredItemsWidget
        widget={widget as StorefrontWidget<ColoredItemsWidgetType>}
        widgetId={widget.widget.name}
      />
    ),

    DIVIDER_WIDGET: (
      <DividerWidget
        widget={widget as StorefrontWidget<DividerWidgetType>}
        widgetId={widget.widget.name}
      />
    ),

    TWO_COLUMNS: (
      <TwoColumnsWidget
        widget={widget as StorefrontWidget<TwoColumnsWidgetType>}
        widgetId={widget.widget.name}
      />
    ),

    JOINED_WIDGET: (
      <JoinedWidget
        joinedFactory={(w: StorefrontWidget) => storefrontWidgetRegistry(w, opts)}
        widget={widget as StorefrontWidget<JoinedWidgetType>}
        widgetId={widget.widget.name}
      />
    ),

    CATEGORY_TREE: <PlaceholderWidget widgetId="CATEGORY_TREE" />,
    CATEGORY_TOOLBAR: <PlaceholderWidget widgetId="CATEGORY_TOOLBAR" />,
    PRODUCT_LIST: <PlaceholderWidget widgetId="PRODUCT_LIST" />,
    PRODUCT_LIST_1: <PlaceholderWidget widgetId="PRODUCT_LIST_1" />,
    CUSTOM_WIDGET_ID: <PlaceholderWidget widgetId="CUSTOM_WIDGET_ID" />,
    WIDGET_RELATED_PRODUCTS: <PlaceholderWidget widgetId="WIDGET_RELATED_PRODUCTS" />,
    WIDGET_TAG_PRODUCTS: <PlaceholderWidget widgetId="WIDGET_TAG_PRODUCTS" />,
    ...opts.extensions
  }

  return dictionary[widget.type as unknown as StorefrontWidgetTypeHandler]
}
