'use client'
import type { FC } from 'react'
import { createCn } from 'shared-utils'
import { GrandDivider, GrandSection } from 'ui'
import type { StorefrontWidget, DividerWidgetType } from 'ecosystem'
import { type WidgetImplementation } from '../registry/typings'

const cn = createCn('divider-widget')

const WIDGET_CN = {
  SECTION: 'section',
  SECTION_CONTAINER: 'section_container',
  DIVIDER: 'divider'
}

type DividerWidgetProps = WidgetImplementation<StorefrontWidget<DividerWidgetType>>

const DividerWidget: FC<DividerWidgetProps> = ({ widgetId, ...props }) => {
  return (
    <GrandSection
      className={cn(WIDGET_CN.SECTION)}
      contentContainerProps={{
        className: cn(WIDGET_CN.SECTION_CONTAINER),
        p: 0,
        px: {
          base: 4,
          md: 6
        },
        alignItems: 'center'
      }}
      py={0}
      sectionId={widgetId}
      {...props}>
      <GrandDivider
        borderColor="primary"
        borderWidth="0 0 1px"
        className={cn(WIDGET_CN.DIVIDER)}
        opacity={1}
      />
    </GrandSection>
  )
}

export default DividerWidget
