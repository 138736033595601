'use client'

import { Fragment } from 'react'
import { VStack } from '@chakra-ui/react'
import type { StorefrontWidget, SummaryBoxesButtonWidgetType } from 'ecosystem'
import { GrandSection, GrandText } from 'ui'
import { WIDGET_GROUP_CN } from '../../constants'
import { type WidgetImplementation } from '../../registry/typings'
import { summaryBoxesButtonCn as cn } from './constants'
import SummaryBoxesButtonWidgetItem from './SummaryBoxesButtonWidgetItem'

type SummaryBoxesButtonWidgetProps = WidgetImplementation<
  StorefrontWidget<SummaryBoxesButtonWidgetType>
>
const SummaryBoxesButtonWidget = ({
  widget,
  widgetId,
  ...props
}: SummaryBoxesButtonWidgetProps) => {
  return (
    <GrandSection
      className={cn(WIDGET_GROUP_CN.SECTION)}
      contentContainerProps={{
        className: cn(WIDGET_GROUP_CN.SECTION_CONTAINER)
      }}
      sectionId={widgetId}
      title={widget.widget.title}
      titleProps={{
        className: cn(WIDGET_GROUP_CN.SECTION_TITLE),
        containerProps: {
          className: cn(WIDGET_GROUP_CN.SECTION_TITLE_CONTAINER)
        },
        decorationProps: {
          className: cn(WIDGET_GROUP_CN.SECTION_TITLE_DECORATION)
        }
      }}
      {...props}>
      {widget.widget.description ? (
        <GrandText className={cn(WIDGET_GROUP_CN.SECTION_DESC)}>
          {widget.widget.description}
        </GrandText>
      ) : null}

      <VStack className={cn(WIDGET_GROUP_CN.WRAPPER)} spacing={10}>
        {widget.widget.items.map((item) => (
          <Fragment key={item.id}>
            <SummaryBoxesButtonWidgetItem {...item} aspectRatio={widget.widget.itemsAspectRatio} />
          </Fragment>
        ))}
      </VStack>
    </GrandSection>
  )
}

export default SummaryBoxesButtonWidget
