'use client'

import type { FC } from 'react'
import type { StorefrontWidget, VideoWidgetType } from 'ecosystem'
import { GrandSection, VideoBox } from 'ui'
import { type WidgetImplementation } from '../registry/typings'

type WidgetVideoProps = WidgetImplementation<StorefrontWidget<VideoWidgetType>>

const WidgetVideoSection: FC<WidgetVideoProps> = ({ widget, widgetId }) => {
  return (
    <GrandSection contentContainerProps={{ p: 4 }} py={0} sectionId={widgetId}>
      <VideoBox
        h="full"
        maxH="auto"
        maxW="auto"
        p={0}
        playback={false}
        autoplay={false}
        loop={false}
        source={widget.widget.videoUrl}
        w="full"
      />
    </GrandSection>
  )
}

export default WidgetVideoSection
