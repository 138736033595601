'use client'
import type { FC } from 'react'
import WidgetsHandler from 'storefront-modules/widgets/components/WidgetsHandler'
import { widgetsConfig } from '#/src/custom/widgets/widgets.config'
import { StorefrontWidget } from 'ecosystem'

interface CustomWidgetsHandlerProps {
  widgets: StorefrontWidget[]
}

const CustomWidgetsHandler: FC<CustomWidgetsHandlerProps> = ({ widgets }) => {
  return <WidgetsHandler factory={widgetsConfig} {...{ widgets }} />
}

export default CustomWidgetsHandler
