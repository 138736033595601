'use client'
import type { FC } from 'react'
import { useMemo } from 'react'
import type { StackProps } from '@chakra-ui/react'
import { useDisclosure } from '@chakra-ui/react'
import { useRouter } from 'next/navigation'
import type { CategoryDTO } from 'ecosystem'
import { GrandMenu, GrandNextLink } from 'ui'
import { sortCategories } from 'shared-utils'
import ToolbarSubcategoryItem from './ToolbarSubcategoryItem'

interface IToolbarSubcategoryProps extends StackProps {
  subCategory: CategoryDTO
  categoriesUrlPath: string
}

const ToolbarSubcategory: FC<IToolbarSubcategoryProps> = ({ subCategory, categoriesUrlPath }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const router = useRouter()
  const subSubCategories = useMemo(
    () => sortCategories(subCategory.subCategories || []),
    [subCategory.subCategories]
  )

  const menuItems = useMemo(() => {
    const parentItem = {
      id: subCategory.id,
      label: subCategory.name,
      isAvailable: true,
      isHighlighted: true,
      icon: null,
      onClick: () => {
        router.push(`${categoriesUrlPath}/${subCategory.slug}`)
      }
    }

    const mappedSubCategories =
      subSubCategories.map((sc) => ({
        id: sc.id,
        label: sc.name,
        isAvailable: true,
        isHighlighted: false,
        icon: null,
        onClick: () => {
          router.push(`${categoriesUrlPath}/${sc.slug}`)
        }
      })) || []

    return [parentItem, ...mappedSubCategories]
  }, [
    categoriesUrlPath,
    router,
    subCategory.id,
    subCategory.name,
    subCategory.slug,
    subSubCategories
  ])

  const hasChildren = useMemo(() => {
    return Boolean(subSubCategories.length)
  }, [subSubCategories.length])

  return (
    <>
      {subSubCategories.length ? (
        <GrandMenu
          actions={menuItems}
          component={
            <ToolbarSubcategoryItem
              onItemClick={() => {
                isOpen ? onClose() : onOpen()
              }}
              title={subCategory.name}
              w="auto"
              {...{ hasChildren }}
            />
          }
        />
      ) : (
        <GrandNextLink
          aria-label={subCategory.name}
          href={`${categoriesUrlPath}/${subCategory.slug}`}>
          <ToolbarSubcategoryItem title={subCategory.name} w="auto" />
        </GrandNextLink>
      )}
    </>
  )
}

export default ToolbarSubcategory
