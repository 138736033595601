'use client'
import type { FC } from 'react'
import { Flex } from '@chakra-ui/react'
import { GrandSection, GrandText } from 'ui'
import type { BubbleLinkWidgetType, StorefrontWidget } from 'ecosystem'
import { WIDGET_GROUP_CN } from '../../constants'
import { type WidgetImplementation } from '../../registry/typings'
import { bubbleLinkCn as cn } from './constants'
import { BubbleLinkWidgetItem } from './BubbleLinkWidgetItem'

type BubbleLinkWidgetProps = WidgetImplementation<StorefrontWidget<BubbleLinkWidgetType>>

export const BubbleLinkWidget: FC<BubbleLinkWidgetProps> = ({ widget, widgetId }) => {
  return (
    <GrandSection
      className={cn(WIDGET_GROUP_CN.SECTION)}
      contentContainerProps={{
        className: cn(WIDGET_GROUP_CN.SECTION_CONTAINER)
      }}
      sectionId={widgetId}
      title={widget.widget.title}
      titleProps={{
        className: cn(WIDGET_GROUP_CN.SECTION_TITLE),
        containerProps: {
          className: cn(WIDGET_GROUP_CN.SECTION_TITLE_DECORATION)
        },
        decorationProps: {
          className: cn(WIDGET_GROUP_CN.SECTION_TITLE_DECORATION)
        }
      }}>
      {widget.widget.description ? (
        <GrandText className={cn(WIDGET_GROUP_CN.SECTION_DESC)}>
          {widget.widget.description}
        </GrandText>
      ) : null}

      <Flex className={cn(WIDGET_GROUP_CN.WRAPPER)} flexWrap="wrap" gap={4} w="full">
        {widget.widget.items
          ? widget.widget?.items.map((item) => {
              return <BubbleLinkWidgetItem item={item} key={item.id} />
            })
          : null}
      </Flex>
    </GrandSection>
  )
}
