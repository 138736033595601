import type {
  FooterSectionWidgetType,
  RequiredPageProps,
  StoreDTO,
  StorefrontWidget
} from 'ecosystem'
import storefrontApi from '../storefront-api'
import { staticPagesCategoryFactory } from './store'

export async function requiredPagePropsFactory(): Promise<RequiredPageProps> {
  const store: StoreDTO = await storefrontApi.store.getStore({
    query: ['categories']
  })
  const footerWidgets = await footerWidgetsFactory()
  const staticPages = await staticPagesCategoryFactory()

  return {
    categories: store.categories || [],
    footerWidgets,
    staticPages
  }
}

export async function footerWidgetsFactory(): Promise<StorefrontWidget<FooterSectionWidgetType>[]> {
  const footerWidgets = (await storefrontApi.widgets.getWidgetsByView(
    'FOOTER'
  )) as StorefrontWidget<FooterSectionWidgetType>[]
  return footerWidgets.sort((a, b) => a.order - b.order)
}
