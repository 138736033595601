import type { FC } from 'react'
import { useBreakpointValue } from '@chakra-ui/react'
import type { CategoryDTO, StorefrontWidget } from 'ecosystem'
import type { OverridesContextType } from 'ui/lib/overrides'
import { OverridesContextProvider } from 'ui/lib/overrides'
import type { BreadCrumbItem } from 'ui'
import { CategoryToolbar } from '../components'
import { type WidgetImplementation } from '../../widgets/registry/typings'

export interface CategoryToolbarWidgetType {
  name: string
}

type CategoryToolbarWidgetProps = WidgetImplementation<
  StorefrontWidget<CategoryToolbarWidgetType>
> & {
  category: CategoryDTO
  onMore: () => void
  categoriesUrlPath: string
  breadcrumbs?: BreadCrumbItem[]
  overrides?: OverridesContextType
}

const CategoryToolbarWidget: FC<CategoryToolbarWidgetProps> = ({
  breadcrumbs,
  onMore,
  categoriesUrlPath,
  overrides,
  category
}) => {
  return (
    <OverridesContextProvider overrides={{ ...overrides }}>
      <CategoryToolbar
        breadCrumbs={useBreakpointValue({ base: [], lg: breadcrumbs })}
        noImage={useBreakpointValue({ base: true, lg: false })}
        {...{ onMore, categoriesUrlPath, category }}
      />
    </OverridesContextProvider>
  )
}

export default CategoryToolbarWidget
