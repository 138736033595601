'use client'
import { type FunctionComponent, useMemo, useEffect, useRef, useState } from 'react'
import type { StackProps } from '@chakra-ui/react'
import { Center, Flex } from '@chakra-ui/react'
import { TbMovieOff } from 'react-icons/tb'
import useOnScreen from '../hooks/useOnScreen'

const videoMimeTypes = {
  mp4: 'video/mp4',
  webm: 'video/webm',
  mov: 'video/quicktime'
}

interface VideoBoxProps extends StackProps {
  source?: string
  playback?: boolean
  videoSupportDisabled?: boolean
  autoplay?: boolean
  loop?: boolean
  muted?: boolean
  controls?: boolean
  captions?: { src: string; srcLang: string; label: string }[]
}

const VideoBox: FunctionComponent<VideoBoxProps> = ({
  videoSupportDisabled,
  source,
  playback,
  controls = true,
  autoplay = false,
  loop = false,
  muted = true,
  captions = [],
  ...props
}) => {
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const [isError, setIsError] = useState<boolean>(false)
  const isIntersecting = useOnScreen(videoRef)
  const mimeType = useMemo(() => {
    const extension = source?.split('.').pop() as keyof typeof videoMimeTypes
    return videoMimeTypes[extension ?? 'mp4']
  }, [source])

  useEffect(() => {
    // Fix ios bug to show poster of the video https://stackoverflow.com/questions/41255841/how-to-get-html5-video-thumbnail-without-using-poster-on-safari-or-ios
    videoRef.current?.load()
  }, [])

  useEffect(() => {
    const video = videoRef.current

    if (video) {
      const isPlaying =
        video.currentTime > 0 &&
        !video.paused &&
        !video.ended &&
        video.readyState > video.HAVE_CURRENT_DATA

      if ((autoplay || playback) && isIntersecting && !isPlaying) {
        void video.play()
      } else if (isPlaying && !autoplay && !playback) {
        video.pause()
        video.currentTime = 0
      }
    }
  }, [autoplay, playback, isIntersecting, source]) // Dependencies that control playback

  const handleError = () => {
    setIsError(true)
  }

  const handlePlay = () => {
    setIsError(false)
  }

  return (
    <Flex
      className="VideoBox"
      h="100%"
      maxH="40vh"
      maxW={[320, 360, 600]}
      mx="auto"
      position="relative"
      {...props}>
      {(videoSupportDisabled || isError) && (
        <Center color="gray.700" h="100%" left={0} position="absolute" top={0} w="100%">
          <TbMovieOff size="40px" />
        </Center>
      )}

      {!videoSupportDisabled && !isError && (
        // eslint-disable-next-line jsx-a11y/media-has-caption -- False negative?
        <video
          disablePictureInPicture
          height="100%"
          muted={muted}
          loop={loop}
          autoPlay={autoplay}
          controls={controls}
          onError={handleError}
          onPlay={handlePlay}
          onPlaying={handlePlay}
          playsInline
          ref={videoRef}
          width="100%">
          <source src={source} type={mimeType} />
          {captions.map((caption, index) => (
            <track
              key={caption.src + index.toString()}
              src={caption.src}
              srcLang={caption.srcLang}
              label={caption.label}
              kind="captions"
              default={index === 0}
            />
          ))}
          Your browser doesn&apos;t support HTML5 video
        </video>
      )}
    </Flex>
  )
}

export default VideoBox
