import { type FC, useCallback, useMemo, useState } from 'react'
import { Box, Input, Text, VStack } from '@chakra-ui/react'

interface SearchableDropdownItem {
  id: string
  label: string
}

interface SearchableDropdownProps {
  items: SearchableDropdownItem[]
  selectedItem: SearchableDropdownItem | null
  setSelectedItem: (item: SearchableDropdownItem | null) => void
  placeholder?: string
}

const SearchableDropdown: FC<SearchableDropdownProps> = ({
  placeholder,
  items,
  selectedItem,
  setSelectedItem
}) => {
  const [searchQuery, setSearchQuery] = useState<string>('')

  const filteredItems = useMemo(() => {
    return items.filter((item) => item.label.toLowerCase().includes(searchQuery.toLowerCase()))
  }, [items, searchQuery])

  const handleSelectItem = useCallback(
    (item: SearchableDropdownItem) => {
      const isSameItem = selectedItem?.id === item.id
      setSelectedItem(isSameItem ? null : item)
    },
    [selectedItem, setSelectedItem]
  )

  return (
    <VStack w="full" align="start" spacing={2}>
      <Input
        rounded="md"
        placeholder={placeholder || 'Search items...'}
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        size="sm"
      />
      <Box w="full" maxH="200px" overflowY="auto" borderWidth="1px" borderRadius="md" p={2}>
        {filteredItems.length ? (
          filteredItems.map((item) => (
            <Box
              key={item.id}
              px={3}
              py={2}
              cursor="pointer"
              borderRadius="md"
              bg={selectedItem?.id === item.id ? 'blue.100' : 'white'}
              _hover={{ bg: 'blue.50' }}
              onClick={() => handleSelectItem(item)}>
              <Text fontSize="sm">{item.label}</Text>
            </Box>
          ))
        ) : (
          <Text fontSize="sm" color="gray.500">
            No results found
          </Text>
        )}
      </Box>
    </VStack>
  )
}

export default SearchableDropdown
