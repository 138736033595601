'use client'
import type { FC } from 'react'
import { Grid } from '@chakra-ui/react'
import type { CircleLinkWidgetType, StorefrontWidget } from 'ecosystem'
import { GrandSection, GrandText } from 'ui'
import { WIDGET_GROUP_CN } from '../../constants'
import { type WidgetImplementation } from '../../registry/typings'
import { circleLinkCn as cn } from './constants'
import CircleLink1WidgetItem from './CircleLink1WidgetItem'

type CircleLink1Widget = WidgetImplementation<StorefrontWidget<CircleLinkWidgetType>>

const CircleLink1Widget: FC<CircleLink1Widget> = ({ widget, widgetId, ...props }) => {
  const items = widget.widget.items

  return (
    <GrandSection
      className={cn(WIDGET_GROUP_CN.SECTION)}
      contentContainerProps={{
        className: cn(WIDGET_GROUP_CN.SECTION_CONTAINER)
      }}
      sectionId={widgetId}
      title={widget.widget.title || ''}
      titleProps={{
        className: cn(WIDGET_GROUP_CN.SECTION_TITLE),
        containerProps: {
          className: cn(WIDGET_GROUP_CN.SECTION_TITLE_CONTAINER)
        },
        decorationProps: {
          className: cn(WIDGET_GROUP_CN.SECTION_TITLE_DECORATION)
        }
      }}
      {...props}>
      {widget.widget.description ? (
        <GrandText className={cn(WIDGET_GROUP_CN.SECTION_DESC)}>
          {widget.widget.description}
        </GrandText>
      ) : null}

      <Grid
        className={cn(WIDGET_GROUP_CN.WRAPPER)}
        gap={10}
        justifyContent="center"
        templateColumns={{
          base: `repeat(auto-fit, minmax(210px, 1fr))`
        }}
        width="100%">
        {items.map((item) => (
          <CircleLink1WidgetItem key={item.id} {...{ item }} />
        ))}
      </Grid>
    </GrandSection>
  )
}

export default CircleLink1Widget
