'use client'
import { Flex, Grid, GridItem, useBreakpointValue } from '@chakra-ui/react'
import type { ArticleBoxesWidgetType, StorefrontWidget } from 'ecosystem'
import { GrandNextLink, GrandSection, GrandText, ScrollDragContainer } from 'ui'
import { useAppPaths } from 'ui/hooks'
import { hrefFromAppPaths } from 'shared-utils'
import { WIDGET_GROUP_CN } from '../../constants'
import { type WidgetImplementation } from '../../registry/typings'
import { articleBoxesCn as cn } from './constants'
import ArticleBoxes1WidgetItem from './ArticleBoxes1WidgetItem'

type ArticleBoxes1WidgetProps = WidgetImplementation<StorefrontWidget<ArticleBoxesWidgetType>>

const ArticleBoxes1Widget = ({ widget, widgetId, ...props }: ArticleBoxes1WidgetProps) => {
  const templateColumns = useBreakpointValue({
    base: 'repeat(1, 1fr)',
    md: `repeat(${Math.max(widget.widget.items.length, 3)}, minmax(${
      widget.widget.itemDesktopMinWidth || '360px'
    }, 1fr))`
  })
  const appPaths = useAppPaths()

  return (
    <GrandSection
      className={cn(WIDGET_GROUP_CN.SECTION)}
      contentContainerProps={{
        className: cn(WIDGET_GROUP_CN.SECTION_CONTAINER)
      }}
      sectionId={widgetId}
      title={widget.widget.title}
      titleProps={{
        className: cn(WIDGET_GROUP_CN.SECTION_TITLE),
        containerProps: {
          className: cn(WIDGET_GROUP_CN.SECTION_TITLE_CONTAINER)
        },
        decorationProps: {
          className: cn(WIDGET_GROUP_CN.SECTION_TITLE_DECORATION)
        }
      }}
      {...props}>
      {widget.widget.description ? (
        <GrandText className={cn(WIDGET_GROUP_CN.SECTION_DESC)}>
          {widget.widget.description}
        </GrandText>
      ) : null}

      <ScrollDragContainer cursor="default">
        <Grid
          className={cn(WIDGET_GROUP_CN.WRAPPER)}
          gap={{
            base: 10,
            md: 5
          }}
          templateColumns={templateColumns}>
          {widget.widget.items.map((item) => (
            <GridItem colSpan={1} display="flex" key={item.id}>
              <ArticleBoxes1WidgetItem flex="1" {...item} />
            </GridItem>
          ))}
        </Grid>
      </ScrollDragContainer>

      {widget.widget.btnLabel && widget.widget.pathObj ? (
        <Flex
          className={cn(WIDGET_GROUP_CN.SECTION_LINK_WRAPPER)}
          justifyContent={{
            base: 'center',
            md: 'flex-end'
          }}
          w="full">
          <GrandNextLink
            aria-label={widget.widget.btnLabel}
            href={hrefFromAppPaths(widget.widget.pathObj, appPaths)}
            isButton
            isExternal={widget.widget.isExternal}
            minW="220px">
            {widget.widget.btnLabel}
          </GrandNextLink>
        </Flex>
      ) : null}
    </GrandSection>
  )
}

export default ArticleBoxes1Widget
