'use client'
import { GridItem, SimpleGrid } from '@chakra-ui/react'
import type { StorefrontWidget, SummaryBoxesButtonWidgetType } from 'ecosystem'
import { GrandSection, GrandText } from 'ui'
import { WIDGET_GROUP_CN } from '../../constants'
import { type WidgetImplementation } from '../../registry/typings'
import { summaryBoxesButtonCn as cn } from './constants'
import SummaryBoxesButton1WidgetItem from './SummaryBoxesButton1WidgetItem'

type SummaryBoxesButton1WidgetProps = WidgetImplementation<
  StorefrontWidget<SummaryBoxesButtonWidgetType>
>

const SummaryBoxesButton1Widget = ({
  widget,
  widgetId,
  ...props
}: SummaryBoxesButton1WidgetProps) => {
  return (
    <GrandSection
      className={cn(WIDGET_GROUP_CN.SECTION)}
      contentContainerProps={{
        className: cn(WIDGET_GROUP_CN.SECTION_CONTAINER)
      }}
      sectionId={widgetId}
      title={widget.widget.title}
      titleProps={{
        className: cn(WIDGET_GROUP_CN.SECTION_TITLE),
        containerProps: {
          className: cn(WIDGET_GROUP_CN.SECTION_TITLE_CONTAINER)
        },
        decorationProps: {
          className: cn(WIDGET_GROUP_CN.SECTION_TITLE_DECORATION)
        }
      }}
      {...props}>
      {widget.widget.description ? (
        <GrandText className={cn(WIDGET_GROUP_CN.SECTION_DESC)}>
          {widget.widget.description}
        </GrandText>
      ) : null}

      <SimpleGrid className={cn(WIDGET_GROUP_CN.WRAPPER)} columns={2} spacing={5}>
        {widget.widget.items.map((item) => (
          <GridItem colSpan={1} display="flex" key={item.id}>
            <SummaryBoxesButton1WidgetItem
              aspectRatio={widget.widget.itemsAspectRatio}
              flex="1"
              {...item}
            />
          </GridItem>
        ))}
      </SimpleGrid>
    </GrandSection>
  )
}

export default SummaryBoxesButton1Widget
