import type { FC, ReactElement } from 'react'
import type { WrapProps } from '@chakra-ui/react'
import { Box, Wrap, WrapItem } from '@chakra-ui/react'
import { parseObjectToClassnames, showsOnMobile } from 'shared-utils'
import { type DynamicWidgetProps, type StorefrontWidget } from 'ecosystem'

interface IWidgetsProps extends WrapProps {
  factory: (widget: StorefrontWidget, dynamicProps?: DynamicWidgetProps) => ReactElement
  widgets?: StorefrontWidget[]
  dynamicProps?: DynamicWidgetProps
}

const WidgetsHandler: FC<IWidgetsProps> = ({ factory, widgets, dynamicProps, ...props }) => {
  if (!widgets || widgets.length === 0) {
    return null
  }

  const orderedWidgets = widgets.sort((a, b) => a.order - b.order)

  return (
    <Wrap {...props} spacing={0} w="full">
      {orderedWidgets.map((widget) => {
        const styling = widget.widget.styling
        return (
          <WrapItem
            display={{
              base: showsOnMobile(widget),
              md: 'initial'
            }}
            key={widget.id}
            w="full">
            <Box sx={styling ? parseObjectToClassnames(styling) : undefined}>
              {factory(widget, dynamicProps)}
            </Box>
          </WrapItem>
        )
      })}
    </Wrap>
  )
}

export default WidgetsHandler
