'use client'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import type { BoxProps, FlexProps } from '@chakra-ui/react'
import { Box, Flex } from '@chakra-ui/react'
import { isMobileOnly } from 'react-device-detect'
import type { GrandDividerProps } from 'ui'
import {
  ContentContainer,
  GrandAspectRatio,
  GrandDivider,
  GrandHeading,
  GrandNextLink,
  GrandRibbon,
  GrandText,
  ResponsiveTextBox
} from 'ui'
import type { HeroWidgetType, StorefrontWidget } from 'ecosystem'
import type { DynamicHeroImageProps } from 'ui/common/DynamicHeroImage'
import DynamicHeroImage from 'ui/common/DynamicHeroImage'
import { useUIGlobalsContext } from 'ui/lib/ui-globals/hooks'
import GrandOverlay from 'ui/common/GrandOverlay'
import { hrefFromAppPaths } from 'shared-utils'
import { useAppPaths } from 'ui/hooks'
import { type WidgetImplementation } from '../registry/typings'

export type HeroWidgetProps = WidgetImplementation<
  StorefrontWidget<HeroWidgetType>,
  {
    dividerProps?: GrandDividerProps
    customComponents?: {
      ImageHero?: FC<HeroWidgetImageProps>
      Wrapper?: FC<HeroWidgetWrapperProps>
    }
  }
>

export type HeroWidgetWrapperProps = BoxProps & {
  type: 'RATIO' | 'NORATIO'
}

export interface HeroWidgetImageProps extends DynamicHeroImageProps {
  type: 'RATIO' | 'NORATIO'
}

const _ImageHero: FC<HeroWidgetImageProps> = ({ type, ...props }) => {
  if (type === 'RATIO') {
    return <DynamicHeroImage {...props} />
  }
  return (
    <Box>
      <DynamicHeroImage objectFit="cover" {...props} />
    </Box>
  )
}

const _Wrapper: FC<HeroWidgetWrapperProps> = ({ type, children, ...props }) => {
  const { mobileItemHeight } = useUIGlobalsContext()
  if (type === 'RATIO') {
    return (
      <GrandAspectRatio<FlexProps, 'w'>
        breakpoints={{
          base: '4:3',
          sm: '16:9'
        }}
        {...props}>
        {children}
      </GrandAspectRatio>
    )
  }
  return (
    <Flex
      h={{ base: mobileItemHeight, md: 'auto' }}
      pb="30%"
      position="relative"
      w="full"
      {...props}>
      {children}
    </Flex>
  )
}

const Hero: FC<HeroWidgetProps> = ({
  widgetId,
  widget: {
    widget: {
      alt,
      mobileImgUrl,
      imgUrl,
      withAspectRatio,
      mobileLayout,
      title,
      ribbonText,
      slogan,
      sub,
      cta,
      headingTag
    }
  },
  customProps = {}
}) => {
  const { customComponents = {}, dividerProps } = customProps
  const { ImageHero = _ImageHero, Wrapper = _Wrapper } = customComponents
  const [deviceImgUrl, setDeviceImgUrl] = useState<string | null>(() =>
    mobileImgUrl ? null : imgUrl
  )
  const appPaths = useAppPaths()

  useEffect(() => {
    if (mobileImgUrl && isMobileOnly) {
      setDeviceImgUrl(mobileImgUrl)
    } else {
      setDeviceImgUrl(imgUrl)
    }
  }, [imgUrl, mobileImgUrl])

  const type = withAspectRatio ? 'RATIO' : 'NORATIO'
  const isCompactMode = mobileLayout === 'COMPACT'

  return (
    <Box id={widgetId}>
      <Flex
        className="hero__wrapper"
        direction="column"
        display={{
          base: isCompactMode ? 'flex' : 'none',
          lg: 'none'
        }}
        w="full">
        <GrandHeading
          className="hero__title"
          fontSize="2xl"
          headingTag="h1"
          textAlign="center"
          title={title || ''}
        />
        <GrandDivider />
      </Flex>

      <Wrapper
        className="hero__wrapper"
        color="text.light"
        display={{
          base: isCompactMode ? 'none' : 'flex',
          lg: 'flex'
        }}
        mt={ribbonText ? 5 : 0}
        {...{ type }}>
        {ribbonText ? <GrandRibbon ribbonText={ribbonText} /> : null}

        <ContentContainer
          alignItems="center"
          bottom={0}
          className="hero__content-container"
          direction="column"
          h="100%"
          justifyContent="center"
          left={0}
          position="absolute"
          px={{
            base: 2,
            md: 8
          }}
          right={0}
          spacing={6}
          textAlign="center"
          zIndex={2}>
          {slogan ? (
            <GrandText className="hero__slogan" fontSize={['md', 'lg']} textStyle="slogan">
              {slogan}
            </GrandText>
          ) : null}

          {title ? (
            <ResponsiveTextBox
              as={headingTag ? headingTag : 'h1'}
              className="hero__title"
              color="text.light"
              fontSize={['4xl', '5xl']}
              fontWeight="medium"
              w="100%">
              {title}
            </ResponsiveTextBox>
          ) : null}

          {title || sub ? (
            <GrandDivider
              borderColor="text.light"
              borderWidth={1}
              className="hero__divider"
              opacity={1}
              w="40%"
              {...dividerProps}
            />
          ) : null}

          {sub ? (
            <GrandText className="hero__sub" fontSize={['lg', 'xl']}>
              {sub}
            </GrandText>
          ) : null}

          {cta?.pathObj ? (
            <GrandNextLink
              aria-label={cta.label}
              className="hero__cta"
              href={hrefFromAppPaths(cta.pathObj, appPaths)}
              isButton
              isExternal={cta.isExternal}>
              {cta.label}
            </GrandNextLink>
          ) : null}
        </ContentContainer>

        <GrandOverlay
          className="hero__overlay"
          {...(deviceImgUrl ? {} : { backdropFilter: 'blur(10px)' })}
        />

        {!!(deviceImgUrl ?? imgUrl) && (
          <ImageHero
            alt={alt ?? 'hero'}
            src={deviceImgUrl ?? imgUrl}
            {...(deviceImgUrl
              ? {}
              : {
                  quality: 1,
                  sizes: '32vw'
                })}
            {...{ type }}
          />
        )}
      </Wrapper>
    </Box>
  )
}

export default Hero
